li.nav-item{
    margin: 20px;
    text-transform: uppercase;
}
.nav{
    font-size:large;
}
.navbar{
    padding: 1rem 2rem;
    font-weight: 500;
}

.dropdown-item:hover {
    background-color: #d5d5d5;
}

.navbar-nav .dropdown-menu {
    text-align: center
}

@media screen and (min-width: 992px) {
    .navbar-nav .dropdown-menu {
        text-align: left
    }
}