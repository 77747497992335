body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 155px;
  color: #606060;
  font-family: "Helvetica Neue", Helvetica, Arial, "Roboto", sans-serif, sans-serif;
  overflow-x: hidden;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  background-color: #f5f5f5;
}

h1{
  margin-top: 7rem;
  margin-bottom: 2rem;
  color: rgb(58,139,193);
  font-weight: 400;
}

.banner-content-product {
  border: 3px  solid;
  padding: 5px 10px 5px;
  border-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANQAAACrCAYAAAAeqrIMAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAXEQAAFxEByibzPwAAAd5JREFUeF7t3UsJA1EQRcGRGikjKbSCOJsfiYJ3NoEquNAGzrq3mflcO8xsbe/LHdSxAcuelgQFDUFBSFAQEhSEBAUhQUFIUBASFIQEBSFBQUhQEBIUhAQFIUFBSFAQEhSEBAUhQUFIUBASFIQEBSFBQUhQEBIUhAQFIUFBSFAQEhSEBAUhQUFIUBASFIQEBSFBQUhQEBIUhAQFIUFBSFAQEhSEBAUhQUFIUBASFIQEBSFBQUhQEBIUhAQFIUFBSFAQEhSEBAUhQUFIUBASFIQEBSFBQUhQEBIUhAQFIUFBSFAQEhSEBAUhQUFIUBASFIS0BAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwJ+bmf17Aguelvy0gcbTkqCgISgICQpCgoKQoCAkKAgJCkKCgpCgICQoCAkKQoKCkKAgJCgICQpCgoKQoCAkKAgJCkKCgpCgICQoCAkKQoKCkKAgJCgICQpCgoKQoCAkKAgJCkKCgpCgICQoCAkKQoKCkKAgJCgICQpCgoKQoCAkKAgJCkKCgpCgICQoCAkKQoKCkKAgJCgICQpCgoKQoCAkKAgJCkKCgpCgICQoCAkKQoKCkKAgJCgICQpCgoKQoCAkKAgJCkKCgtAvqP0+zGx18zoB7kVxX752fdsAAAAASUVORK5CYII=') 1;
  border-image-repeat: stretch;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 2rem;
  min-width: 50%;
  margin: 0 auto;
}

.navbar-light .navbar-nav .nav-link.active{
  color: rgb(58,139,193);
}

a.link, a.link:link, a.link:visited{
  color: rgb(81,147,184);
  text-decoration: none;
  font-size: large;
}

a.link:hover{
  color: rgb(58,139,193);
  font-weight: 600;
}

div {
  transition: all 0.2s;
}
li.nav-item{
    margin: 20px;
    text-transform: uppercase;
}
.nav{
    font-size:large;
}
.navbar{
    padding: 1rem 2rem;
    font-weight: 500;
}

.dropdown-item:hover {
    background-color: #d5d5d5;
}

.navbar-nav .dropdown-menu {
    text-align: center
}

@media screen and (min-width: 992px) {
    .navbar-nav .dropdown-menu {
        text-align: left
    }
}
.banner-content {
    border: 3px solid;
    padding: 5px 10px 5px;
    border-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANQAAACrCAMAAAFec3KoAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAASUExURQAAAP////////////7+/v///3DSMEoAAAAGdFJOUwBggP+wwNv0ngIAAAAJcEhZcwAAFxEAABcRAcom8z8AAAEbSURBVHhe7dw5jsJAAERRD2buf+VJOiAxEl1Dy9V6LyEAq/SFxSKW4/e8dJzHtR2uW+1O7cuvuzRuAB+ZO3Ec9er+R93fY1x+xr38quCoGeNYAIA9LHx1YypkKmQqZCpkKrRwCvh/HphCpkKmQqZCpkJ7Tj3GR07fN/dR2IznaFvA+RcyFTIVMhUyFTIVMhUyFTIVMhUyFVo4BQDT5n4/MsUTfshUyFTIVMhUyFTIVMhUyFTIVMhUaOHXcH5Wdq3y/t+NSolqIaqFqBaiWohqIaqFqBaiWohqIaqFqBaiWohqIaqFqBaiWmwaBQAAsIA3iR1EtRDVQlQLUS1EtRDVQlQLUS1EtRDVQlQLUS1EtRDVQlQLUS12jDrOPy4DCDlmhAw1AAAAAElFTkSuQmCC') 1;
    border-image-repeat: stretch;
    width: 98%;
    font-size: 2rem;
    line-height: 3rem; 
    min-width: 50%;
    display: table;
    margin: 0 auto;
}


.banner {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    color: white;
    margin: 0 !important;
    display: flex;
    min-height: 300px;
    object-fit: cover;
    
}

@media screen and (min-width: 768px) {
    .banner-content {
        padding: 10px 50px 15px;
        font-size: 3rem;
        line-height: 4rem;
    }

    .banner {
        height: 500px;
    }

    .banner-content {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
}

.text-align-justify{
    text-align: justify;
    font-size: 20px;
    padding: 10px;
}

.icon-ul{
    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAMAAABhq6zVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABa1BMVEX////+//v9/Pn5+fv3+Pn8/PL9/vz+/vz8//7+/v7//vz+//74/Pnv9/qryuB0pL7C3+zx+ff9/PT+/vr9/v///f3///79///m9Ph1qcc5ibs9j8I/ibWVwNXv+fj8/vz8/v///f78/Prg8fVpp809i7s4jL46jL46jL0/jbyRwdfr9/j+/fv5/f/q9flwrMg+jr46i702j8BClLuZv9f3+Pz8/v6OuNM5jL85i704irw1jbwyjcNJkb650+JjosQ6i8A6i743jbo5isM9kcKNus16rso5jbw5jL05jL45jLw1j7g5jbpGj7mrzd/X6fFdnr44jb47i700jcM1jrw8jbyCs8vs9ff9/Pr59vXV6fFVmMA+i7s3jLs2jro+jr1xrMLo9Pf5/Pn9/v3+/Pz6+/3c7fRdnL03ibo8jbt+sMjs9vn4/fr5//7+//35+vbV6PKJutR1rsybw9fq9Pf5/Pj5//39/v76//z1U8VzAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAAOwwAADsMBx2+oZAAAAAd0SU1FB+IMEwkFEpEbu9sAAAABb3JOVAHPoneaAAAAo0lEQVQI12NgYGBkYmZhZWPn4ORiYODm4eXjFxAUEhYRZRATl5CUkpaRlZNXUGRQUlZRVVPX0NTS1tFl0NM3MFRXVzcyNjE1YzDXsLC0UtewtrG1Y2ewd3AESmg4Obu46jC4uXuoO3p6efv4+rEz+AcEBjkEhziFhoVHMERGRcfExnnEJyQmJTOkpKalW2dkZmXn5OYxcHDkFxQWFZeUlpVXAACbuh+dlqJLpgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0xMi0xOVQwOTowNToxOCswMDowMBbNDrAAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMTItMTlUMDk6MDU6MTgrMDA6MDBnkLYMAAAAAElFTkSuQmCC)
}

.icon-ul-check{
    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAIAAABVH8vfAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH4gwUAi4HGzR1AQAAAAFvck5UAc+id5oAAAOJSURBVDjLlZRLb1ZVFIbftfY+5/T2wQcINAi0NhCuoVYgKkaNBhUUTTCBgY6MxqCJf0MHDowTHWgiYeAlMQ6EAULCgDDgZiRBVGojtJISbRsopZx+5+y1XgfEgEUSWD/gybufvdYr7o57GwImCHRlRQRKhFMICKiI90gBQJAAULtX016MlXUhWNAW8yCA3QdIAaWBPmnFNycvHjo92Ggr3nrp8U1LOgtQ7x0ECkxKz48Mjn334/CINH5pFZ8fOF6ag7gvED3Jr6PX9h05d8WCiU0Tk7WZ0QmtIQ4X1ECqAd5hpRIYhEQiJ5PvPTI4lnLXZGCX1i9s7G3PhRpUhAIDayCp3PmDAkDpQivBL46d+3n8Wg0XR6f7w/OL7QN9eaCLaWQSOqECCTS5PZOAAiXhVhnODI8d+m20FBgcimXtfHtr/+IiRpogxapODMElBiDCZzkTUtRNdGSq9dmBU2VVQKABc7TasXn16oWNghSogspYTKVwabKaqNhicMjtjgR0YjLxq6NDF1pdDhNKe9Jne7q3re+NQRIB5up5HBq//tH+E8MTaHbJnm0btix/IIoEgQgoMGdyOXhu5NjFy0pLIRiylQ3f/eTaZhYUlKgEAAmLn3795J/XBDqd/Mz5S8vndy2c21FEAHAIaL+OT+09fHasChWoWfscv/HOjk2rFzdyERURAQQQaJblcJmRrLTsSio++eHs9z8NX63MbSb4zN/TrU8PHh8t1SRWRZHTX93c8+jSRodCRW63qa9s7B5YFCGEZKSPWvu+Y79/feLCRAql2ben/xi6KiVpqIPbmgXZy4/0dQYGzFo5SFlfH7nqH+w/NTzmLYAqlYdGtK2r5m18aMGHh4emWsIwA+XCts73d21e28wyUSDcfNEtkHly8q8b9cf7Tx2/nGa0InP1PKdlYtMOYWLULi3feGrNrv6+XG8t6n9PmiFAFnXk7+3csnVVMwYGIjMx6JSIi7hkufszK7pf3NCb6b9u7+wGIYQa6d25vPt8/851y5qhlbJWUisgeRZo9fIO7t6yrilJZomZBQIhCBGYF/jmYytfe2LF3KJE8GS0uuxu9z3bN/U0CvHwv1luTrxpn1CCCm/mtqO/N2/v+PLo+UmNbVY+N9C3fsncXCgS75oHkFmdTYDkDDE4UZ48P97zYGNg6Zx5GSIcUEG4D5DQwEQRgxJB4SpmIkTI7t6o/wBixN56XsAzdgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0xMi0yMFQwMjo0NjowNyswMDowMB7pXL8AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMTItMjBUMDI6NDY6MDcrMDA6MDBvtOQDAAAAAElFTkSuQmCC)
}

.hr-introduction{
    margin: 30px;
}

@media screen and (min-width: 768px) {
    .content-download-app{
        padding: 50px;
        text-align: right;
    }
    
    .content-download-web{
        padding: 50px;
        text-align: left;
    }

    .hr-introduction{
        margin: 30px;
    }
}

@media screen and (min-width: 992px) {
    .content-download-app{
        padding: 50px;
        text-align: right;
    }
    
    .content-download-web{
        padding: 50px;
        text-align: left;
    }

    .hr-introduction{
        margin: 30px;
    }
}




.diagram{
    border: solid rgb(230,230,230) 1.7px;
    border-radius: 50px 0;
    height: 400px;
    margin: 0rem 1.5rem;
    font-size: large;
    padding-left: 16px;
    padding-right: 16px;
}

.signup-panel{
    background: white;
    border-radius: 10px;
}


.diagram h2{
    font-weight: 100;
    font-size: 2.5rem;
    color: rgb(150,150,150);
    margin-top: 50px;
}

.diagram img{
    height: 60px;
    margin-top: 35px;
    margin-bottom: 35px;
}

.phone{
    color: rgb(232,67,48);
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold
}

.sub-title{
    text-align: left;
    color: rgb(58,139,193);
    font-size: 30px;
    margin-bottom: 20px;
}

.signup-panel{
    background: white;
    border-radius: 10px;
}

.download-document-app {
    width: 250px;
    height: 250px;
    background-color: white; 
    color: black; 
    border: 2px solid #eaeaea;
    cursor: pointer;
}
  
.download-document-app:hover {
    border: 2px solid #97bfd1;
}
  

.download-document-web{
    width: 250px;
    height: 250px;
    background-color: white; 
    color: black; 
    border: 2px solid #eaeaea;
    cursor: pointer;
}

.download-document-web :hover{
    border: 2px solid #97bfd1;
}

.content-download-app{
    padding: 50px;
    text-align: unset;
}

.content-download-web{
    padding: 50px;
    text-align: unset;
}

.button-question{
    background: white;
    border: 2px #97bfd1 solid;
    width: 600px;
    height: 80px;
    cursor: pointer;
    margin-top: 100px;
    max-width: -webkit-fill-available;
}

h2.question{
    font-size: 1.5rem;
}

@media screen and (min-width: 768px) {
    .content-download-app{
        padding: 50px;
        text-align: right;
    }
    
    .content-download-web{
        padding: 50px;
        text-align: left;
    }

    h2.question{
        font-size: 2rem;
    }
    
}

@media screen and (min-width: 992px) {
    .content-download-app{
        padding: 50px;
        text-align: right;
    }
    
    .content-download-web{
        padding: 50px;
        text-align: left;
    }
}



.mpos-product{
    margin-top: 80px;
}

.text-align-step-1{
    text-align: center; 
    margin-bottom: 50px;
}

.text-align-step-2{
    text-align: center; 
    margin-bottom: 50px;
}

.text-align-step-3{
    text-align: center; 
    margin-bottom: 50px;
}

.style-image-step-2{
    background-repeat: no-repeat;
    padding-top: 30px;
    background-size: cover;
    background-position: center center;
}

.style-image-step-3{
    background-repeat: no-repeat;
    padding-bottom: 100px;
    padding-top: 10px;
    background-size: cover;
    background-position: 100% 100%;
}

.p-l-10rem{
  padding-left: unset;  
}

.p-r-10rem{
    padding-right: unset;
}

.p-t-header-pay-card{
    padding-top: 3rem;
}

.p-b-10rem{
    padding-bottom: 10rem;
}

.icon-ul{
    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAMAAABhq6zVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABa1BMVEX////+//v9/Pn5+fv3+Pn8/PL9/vz+/vz8//7+/v7//vz+//74/Pnv9/qryuB0pL7C3+zx+ff9/PT+/vr9/v///f3///79///m9Ph1qcc5ibs9j8I/ibWVwNXv+fj8/vz8/v///f78/Prg8fVpp809i7s4jL46jL46jL0/jbyRwdfr9/j+/fv5/f/q9flwrMg+jr46i702j8BClLuZv9f3+Pz8/v6OuNM5jL85i704irw1jbwyjcNJkb650+JjosQ6i8A6i743jbo5isM9kcKNus16rso5jbw5jL05jL45jLw1j7g5jbpGj7mrzd/X6fFdnr44jb47i700jcM1jrw8jbyCs8vs9ff9/Pr59vXV6fFVmMA+i7s3jLs2jro+jr1xrMLo9Pf5/Pn9/v3+/Pz6+/3c7fRdnL03ibo8jbt+sMjs9vn4/fr5//7+//35+vbV6PKJutR1rsybw9fq9Pf5/Pj5//39/v76//z1U8VzAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAAOwwAADsMBx2+oZAAAAAd0SU1FB+IMEwkFEpEbu9sAAAABb3JOVAHPoneaAAAAo0lEQVQI12NgYGBkYmZhZWPn4ORiYODm4eXjFxAUEhYRZRATl5CUkpaRlZNXUGRQUlZRVVPX0NTS1tFl0NM3MFRXVzcyNjE1YzDXsLC0UtewtrG1Y2ewd3AESmg4Obu46jC4uXuoO3p6efv4+rEz+AcEBjkEhziFhoVHMERGRcfExnnEJyQmJTOkpKalW2dkZmXn5OYxcHDkFxQWFZeUlpVXAACbuh+dlqJLpgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0xMi0xOVQwOTowNToxOCswMDowMBbNDrAAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMTItMTlUMDk6MDU6MTgrMDA6MDBnkLYMAAAAAElFTkSuQmCC)
}

.p-t-step-3{
    padding-top: 50px;
}

.text-align-left{
    text-align: left;
    font-size: 20px;
    padding: 10px;
}

.img-style-step-3-pay-card{
    max-width: 95%;
    height: 300px;
    object-fit: contain;
    margin-left: 10px;
    margin-right: 10px;
}


.banner-pay-card {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    color: red;
    margin: 0 !important;
    display: flex;
    min-height: 300px;
    object-fit: cover;    
}

.h1-pay-card{
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: rgb(58,139,193);
    font-weight: 400;
}

.m-l-step-1{
    margin-left: unset;
}

.m-t-step-2-pay-card{
    margin-top: unset;
}

.text-align-step-2-image-order{
    text-align: unset; 
}

@media screen and (min-width: 768px) {

    .text-align-step-1{
        text-align: center; 
        margin-bottom: unset;
    }

    .text-align-step-2{
        text-align: center; 
        margin-bottom: unset;
    }

    .text-align-step-3{
        text-align: center; 
        margin-bottom: unset;
    }

    .style-image-step-2{
        background-repeat: no-repeat;
        padding-top: 130px;
        background-size: cover;
        background-position: center center;
        padding-bottom: 130px;
    }

    .style-image-step-3{
        background-repeat: no-repeat;
        padding-bottom: 100px;
        padding-top: 200px;
        background-size: cover;
        background-position: 100% 100%;
    }

    .float-left-pay-card{
        text-align: unset;
        margin-bottom: 20px;
    }

    .p-l-10rem{
        padding-left: 8rem;  
      }

      
    .img-style-step-3-pay-card{
        max-width: 100%;
        height: 250px;
        max-width: unset;
    }

    .p-r-10rem{
        padding-right: 10rem;
    }

    .h1-pay-card{
        margin-top: 7rem;
        margin-bottom: 2rem;
        color: rgb(58,139,193);
        font-weight: 400;
    }

    .p-t-header-pay-card{
        padding-top: 2rem;
    }

    .p-t-step-3{
        padding: 220px;
    }

    .m-t-step-2-pay-card{
        margin-top: unset;
    }

    
    .text-align-step-2-image-order{
        text-align: right; 
    }
}

@media screen and (min-width: 992px) {

    .text-align-step-1{
        text-align: left; 
        margin-bottom: unset;
    }

    .text-align-step-2{
        text-align: left; 
        margin-bottom: 50px;
    }

    .text-align-step-3{
        text-align: left; 
        margin-bottom: unset;
    }

    .style-image-step-2{
        background-repeat: no-repeat;
        padding-top: 230px;
        background-size: cover;
        background-position: center center;
        padding-bottom: 230px;
    }

    .style-image-step-3{
        background-repeat: no-repeat;
        padding-bottom: 100px;
        padding-top: 200px;
        background-size: cover;
        background-position: 100% 100%;
    }

    .p-l-10rem{
        padding-left: 8rem;  
      }

      .img-style-step-3-pay-card{
        max-width: 100%;
        height: 250px;
    }

    .p-r-10rem{
        padding-right: 10rem;
    }

    .h1-pay-card{
        margin-top: 7rem;
        margin-bottom: 2rem;
        color: rgb(58,139,193);
        font-weight: 400;
    }

    .p-t-header-pay-card{
        padding-top: 10rem;
    }

    .p-t-step-3{
        padding: 220px;
    }

    .m-l-step-1{
        margin-left: unset;
    }

    .m-t-step-2-pay-card{
        margin-top: 470px;
    }

    .text-align-step-2-image-order{
        text-align: right; 
    }

    .mpos-product{
        margin-top: 150px;
    }
}



.mockup-image{
    max-width: 100%;
    object-fit: contain;
}

.p-l-10rem{
    padding-left: unset;  
}
  
.p-r-10rem{
    padding-right: unset;
}
  
.p-t-10rem{
    padding-top: 3rem;
}
  
.p-b-10rem{
    padding-bottom: 10rem;
}

  
.p-t-step-3{
    padding-top: -50px;
}

.h1-pay-installment{
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: rgb(58,139,193);
    font-weight: 400;
}

.style-image-step-3{
    background-repeat: no-repeat;
    padding-bottom: 100px;
    padding-top: 10px;
    background-size: cover;
    background-position: 100% 100%;
}

.text-align-step-1{
    text-align: center; 
    margin-bottom: 50px;
}

.text-align-step-2{
    text-align: center; 
    margin-bottom: 50px;
}

.text-align-step-3{
    text-align: center; 
    margin-bottom: 50px;
}

.img-style-step-3-pay-installment{
    max-width: 99%;
    height: 300px;
    object-fit: contain;
    margin-left: 10px;
    margin-right: 10px;
}

@media screen and (min-width: 768px) {
    .mockup-image{
        max-width: 100%;
        max-height: unset;
        object-fit: contain;  
    }

    .p-t-10rem{
        padding-top: 10rem;
    }

    .h1-pay-installment{
        margin-top: 7rem;
        margin-bottom: 2rem;
        color: rgb(58,139,193);
        font-weight: 400;
    }

    .style-image-step-3{
        background-repeat: no-repeat;
        padding-bottom: 100px;
        padding-top: 200px;
        background-size: cover;
        background-position: 100% 100%;
    }

    .text-align-step-1{
        text-align: center; 
        margin-bottom: 50px;
    }
    
    .text-align-step-2{
        text-align: center; 
        margin-bottom: 50px;
    }
    
    .text-align-step-3{
        text-align: center; 
        margin-bottom: 50px;
    }

    .img-style-step-3-pay-installment{
        max-width: 100%;
        height: 250px;
        max-width: unset;
    }

    .p-t-step-3{
        padding: unset;
    }
}

@media screen and (min-width: 992px) {
    .mockup-image{
        max-width: 100%;
        object-fit: contain;
        height: 900px;
    }

    .p-t-10rem{
        padding-top: 10rem;
    }

    .h1-pay-installment{
        margin-top: 7rem;
        margin-bottom: 2rem;
        color: rgb(58,139,193);
        font-weight: 400;
    }

    .style-image-step-3{
        background-repeat: no-repeat;
        padding-bottom: 100px;
        padding-top: 200px;
        background-size: cover;
        background-position: 100% 100%;
    }

    .text-align-step-1{
        text-align: left; 
        margin-bottom: 50px;
    }
    
    .text-align-step-2{
        text-align: left; 
        margin-bottom: 50px;
    }
    
    .text-align-step-3{
        text-align: left; 
        margin-bottom: 50px;
    }

    .img-style-step-3-pay-installment{
        max-width: 100%;
        height: 250px;
    }
}
.m-t{
    margin-top: unset;
}

.m-t-step-2{
    margin-top: 470px;
}

.img-style-step-3{
    max-height: 500px;
    max-width: 100%;
}

.m-t-step-3{
    margin-top: unset;
}

.m-t-step-2{
    margin-top: unset;
}

.text-align-step-2-pay-installment{
    text-align: unset; 
}

.p-t-header-pay-qr{
    padding-top: 2rem;
}

@media screen and (min-width: 768px) {
    .m-t{
        margin-top: 340px;
    }

    .m-t-step-2{
        margin-top: 470px;
    }

    .img-style-step-3{
        max-height: 500px;
        max-width: 100%;
    }

    .m-t-step-3{
        margin-top: 250px;
    }
    
    .m-t-step-2{
        margin-top: unset;
    }

    .text-align-step-2-pay-installment{
        text-align: right; 
    }

    .p-t-header-pay-qr{
        padding-top: 2rem;
    }
}

@media screen and (min-width: 992px) {
    .m-t{
        margin-top: 340px;
    }

    .m-t-step-2{
        margin-top: 470px;
    }

    .img-style-step-3{
        max-height: 500px;
        max-width: 100%;
        object-fit: cover;
    }
    
    .m-t-step-3{
        margin-top: 250px;
    }

    .m-t-step-2{
        margin-top: 470px;
    }

    .text-align-step-2-pay-installment{
        text-align: right; 
    }

    .p-t-header-pay-qr{
        padding-top: 10rem;
    }
}

.center-col-home{
    display: flex;
    justify-content: center;
    align-items: center;
}

.bank-img{
    height: 75px;
}

.overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(to right, rgba(0,0,0,.4) , rgba(0,0,0,.5) );
    width: 100%
}

.border-item-home{
    border: 1px #EAEAEA solid;
    justify-content: center;
    max-width: 300px;
    min-height: 250px;
    padding-left: 10px;
    padding-right: 10px;
}

.img-home-style{
    width: 80px;
    height: 80px;
    margin-top: 40px;
    margin-bottom: 10px;
}

.font-size-home{
    font-size: 20px;
}

.custom-button-home{
    border-color: white ;
    border-width: 2px;
    width: 250px;
    padding: 10px;
    font-weight: bold !important;
    font-size: large;
}

.custom-title-carousel-header{
    font-size: 30px;
    margin-bottom: 5px;
}

.custom-title-carousel-content{
    font-size: 20px;
    margin-bottom: 10px;
}

.border-item-home{
    margin-bottom: 50px;
}

.custom-button-home:hover{
    background: rgb(231, 231, 231);
    color: black;    
}

.center-caption{
    height: 100%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.carousel-caption{
    position: absolute;
    bottom: 20px;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: left;
}

.right{
    float: right;
}

.phone-show{
    text-align: left;
}

.phone-screen{
    width: 100%;
    max-width: 300px;
    margin-left: 400px;
    z-index: 999;
    position: relative;
    margin-bottom: 70px;
}

.phone-detail-border{
    position: absolute;
    width: 600px;
    max-width: 98vw;
    border: solid #e7e7e7 1.5px;
    text-align: left;
    height: 450px;
    margin-top: 150px;
    padding: 10px 50px;
}

.phone-step{
    position: absolute;
    margin-top: 50px;
}

.phone-detail h4{
    font-size: 30px;
    color: #606060;
    font-weight: 400;
    white-space: pre;
}

.phone-detail h4::before{
    content: '____ \A';
    color: rgb(58,139,193);
    display: block;
    height: 50px;
}

.phone-detail ul{
    font-size: 18px;
    color: #606060;
    font-weight: 350;
    width: 350px;
    max-width: calc(97vw - 50px);
    padding-left: 22px;
    padding-right: 17px;
    text-align: justify;
}

.card-type{
    height: 70px;
}


@media screen and (max-width: 767px) {
    .phone-screen{
        margin-left: auto;
        margin-right: auto;
        margin-top: -100px;
    }

    .phone-detail-border{
        position: relative;
        margin-top: 50px;
        padding: 10px 30px;
    }

    .phone-step{
        margin-top: auto;
        position: relative;
    }

    .phone-detail ul{
        padding-right: 5px;
        font-size: 17px;
    }

    .home-slide{
        min-height: 400px;
        object-fit: cover;
    }
}


@media screen and (min-width: 992px) {
    .border-item-home{
        margin-bottom: 50px;
    }

    .custom-title-carousel-header{
        margin-bottom: 10px;
        font-size: 40px;
    }

    .custom-title-carousel-content{
        font-size: 20px;
        margin-bottom: 50px;
    }

    .card-type{
        height: 120px;
    }
}

@media screen and (min-width: 768px) {
    .border-item-home{
        margin-bottom: 50px;
    }

    .custom-title-carousel-header{
        margin-bottom: 10px;
        font-size: 40px;
    }

    .custom-title-carousel-content{
        font-size: 20px;
        margin-bottom: 50px;
    }

    .card-type{
        height: 100px;
    }

    .bank-img{
        height: 90px;
    }

    .overlay{
        background-image: linear-gradient(to right, rgba(0,0,0,0) , rgba(0,0,0,.3) 60%);
    }
}
.footer-top{
    padding: 1rem;
    background: rgb(55,141,190);
    color: white;
    text-align: center;
    margin: 0 !important;
}

.footer-title{
    color: rgb(2,80,145);
    font-size: 1.5rem;
    text-transform: uppercase;
    margin: 1rem 0;
}

.footer-col{
    border-right:#93bbd3 solid 1px;
}

.footer-col-1{
    border-right: none;
}

.footer-col-2{
   border-right: none;
}

.footer-col-3{
   border-right: none;
}

.footer-col-4{
   border-right: none;
}

.footer-bottom{
    background: rgb(212, 216, 215);
    text-align: left;
    margin: 0 !important;
}

.footer-custom{
    text-align: center;
}

.center-icon-left{
    text-align: center;
    margin-bottom: 7px;
}

.center-icon-right{
    text-align: center;
}

.margin-icon-bottom{
    text-align: left;
    margin-left: unset;
}

.text-hotline{
    font-size: 25px
}

.text-email{
 font-size: 20px   
}


@media screen and (min-width: 768px) {
    .footer-col-1{
        border-right: 1px white solid;
   }
   
   .footer-col-2{
       border-right: none;
   }
   
   .footer-col-3{
    border-right: 1px white solid;
   }
   
   .footer-col-4{
       border-right: none;
   }

    .footer-custom{
        text-align: unset;
   }

   .footer-top{
        padding: 5rem;
        background: rgb(55,141,190);
        color: white;
        text-align: left;
    }

    .center-icon-left{
        text-align: center;
    }
    
    .center-icon-right{
        text-align: center;
    }

    .margin-icon-bottom{
        text-align: left;
        margin-left: unset;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
    .text-hotline{
        font-size: 20px
    }
    
    .text-email{
     font-size: 15px
    }
    
    .app-qr{
        margin-left: 30px;
    }

}

@media screen and (min-width: 1200px) {
    .footer-col-1{
        border-right: 1px white solid;
   }
   
   .footer-col-2{
        border-right: 1px white solid;
   }
   
   .footer-col-3{
       border-right: 1px white solid;
   }
   
   .footer-col-4{
        border-right: none;
  }

    .footer-custom{
        text-align: unset;
   }

   .footer-top{
        padding: 5rem;
        background: rgb(55,141,190);
        color: white;
        text-align: left;
    }

    .center-icon-left{
        text-align: left;
    }
    
    .center-icon-right{
        text-align: right;
    }

    .margin-icon-bottom{
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.icon-ul-question{
    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAGCAIAAACepSOSAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACxSURBVBhXY/j///+///++/vx55tnb/v0XmzefXHLs5qMvP3/9+QuS+/3v35lnH8Knbjfv3WHSu8ehZ1vthpPvf/wByX37/ad4xSmLzj2WXXssevab9+517Ntx5NZzkNznn7/9J+4y7jlq1rnXvGe/We8+ywk7l526CpL7/vtv+ZrT1j37Lbr3mnTvNevZ4zZxy/67z0Byf/79u/b6U+SUbY69u6z6Dzr076pZeejjj98Ax9Rz6vD59fcAAAAASUVORK5CYII=)
}
