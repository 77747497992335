
.text-align-justify{
    text-align: justify;
    font-size: 20px;
    padding: 10px;
}

.icon-ul{
    list-style-image: url('../images/icon_li.png')
}

.icon-ul-check{
    list-style-image: url('../images/icon_li_check.png')
}

.hr-introduction{
    margin: 30px;
}

@media screen and (min-width: 768px) {
    .content-download-app{
        padding: 50px;
        text-align: right;
    }
    
    .content-download-web{
        padding: 50px;
        text-align: left;
    }

    .hr-introduction{
        margin: 30px;
    }
}

@media screen and (min-width: 992px) {
    .content-download-app{
        padding: 50px;
        text-align: right;
    }
    
    .content-download-web{
        padding: 50px;
        text-align: left;
    }

    .hr-introduction{
        margin: 30px;
    }
}



