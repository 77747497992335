
.signup-panel{
    background: white;
    border-radius: 10px;
}

.download-document-app {
    width: 250px;
    height: 250px;
    background-color: white; 
    color: black; 
    border: 2px solid #eaeaea;
    cursor: pointer;
}
  
.download-document-app:hover {
    border: 2px solid #97bfd1;
}
  

.download-document-web{
    width: 250px;
    height: 250px;
    background-color: white; 
    color: black; 
    border: 2px solid #eaeaea;
    cursor: pointer;
}

.download-document-web :hover{
    border: 2px solid #97bfd1;
}

.content-download-app{
    padding: 50px;
    text-align: unset;
}

.content-download-web{
    padding: 50px;
    text-align: unset;
}

.button-question{
    background: white;
    border: 2px #97bfd1 solid;
    width: 600px;
    height: 80px;
    cursor: pointer;
    margin-top: 100px;
    max-width: -webkit-fill-available;
}

h2.question{
    font-size: 1.5rem;
}

@media screen and (min-width: 768px) {
    .content-download-app{
        padding: 50px;
        text-align: right;
    }
    
    .content-download-web{
        padding: 50px;
        text-align: left;
    }

    h2.question{
        font-size: 2rem;
    }
    
}

@media screen and (min-width: 992px) {
    .content-download-app{
        padding: 50px;
        text-align: right;
    }
    
    .content-download-web{
        padding: 50px;
        text-align: left;
    }
}


