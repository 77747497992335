.mockup-image{
    max-width: 100%;
    object-fit: contain;
}

.p-l-10rem{
    padding-left: unset;  
}
  
.p-r-10rem{
    padding-right: unset;
}
  
.p-t-10rem{
    padding-top: 3rem;
}
  
.p-b-10rem{
    padding-bottom: 10rem;
}

  
.p-t-step-3{
    padding-top: -50px;
}

.h1-pay-installment{
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: rgb(58,139,193);
    font-weight: 400;
}

.style-image-step-3{
    background-repeat: no-repeat;
    padding-bottom: 100px;
    padding-top: 10px;
    background-size: cover;
    background-position: 100% 100%;
}

.text-align-step-1{
    text-align: center; 
    margin-bottom: 50px;
}

.text-align-step-2{
    text-align: center; 
    margin-bottom: 50px;
}

.text-align-step-3{
    text-align: center; 
    margin-bottom: 50px;
}

.img-style-step-3-pay-installment{
    max-width: 99%;
    height: 300px;
    object-fit: contain;
    margin-left: 10px;
    margin-right: 10px;
}

@media screen and (min-width: 768px) {
    .mockup-image{
        max-width: 100%;
        max-height: unset;
        object-fit: contain;  
    }

    .p-t-10rem{
        padding-top: 10rem;
    }

    .h1-pay-installment{
        margin-top: 7rem;
        margin-bottom: 2rem;
        color: rgb(58,139,193);
        font-weight: 400;
    }

    .style-image-step-3{
        background-repeat: no-repeat;
        padding-bottom: 100px;
        padding-top: 200px;
        background-size: cover;
        background-position: 100% 100%;
    }

    .text-align-step-1{
        text-align: center; 
        margin-bottom: 50px;
    }
    
    .text-align-step-2{
        text-align: center; 
        margin-bottom: 50px;
    }
    
    .text-align-step-3{
        text-align: center; 
        margin-bottom: 50px;
    }

    .img-style-step-3-pay-installment{
        max-width: 100%;
        height: 250px;
        max-width: unset;
    }

    .p-t-step-3{
        padding: unset;
    }
}

@media screen and (min-width: 992px) {
    .mockup-image{
        max-width: 100%;
        object-fit: contain;
        height: 900px;
    }

    .p-t-10rem{
        padding-top: 10rem;
    }

    .h1-pay-installment{
        margin-top: 7rem;
        margin-bottom: 2rem;
        color: rgb(58,139,193);
        font-weight: 400;
    }

    .style-image-step-3{
        background-repeat: no-repeat;
        padding-bottom: 100px;
        padding-top: 200px;
        background-size: cover;
        background-position: 100% 100%;
    }

    .text-align-step-1{
        text-align: left; 
        margin-bottom: 50px;
    }
    
    .text-align-step-2{
        text-align: left; 
        margin-bottom: 50px;
    }
    
    .text-align-step-3{
        text-align: left; 
        margin-bottom: 50px;
    }

    .img-style-step-3-pay-installment{
        max-width: 100%;
        height: 250px;
    }
}