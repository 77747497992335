.diagram{
    border: solid rgb(230,230,230) 1.7px;
    border-radius: 50px 0;
    height: 400px;
    margin: 0rem 1.5rem;
    font-size: large;
    padding-left: 16px;
    padding-right: 16px;
}

.signup-panel{
    background: white;
    border-radius: 10px;
}


.diagram h2{
    font-weight: 100;
    font-size: 2.5rem;
    color: rgb(150,150,150);
    margin-top: 50px;
}

.diagram img{
    height: 60px;
    margin-top: 35px;
    margin-bottom: 35px;
}

.phone{
    color: rgb(232,67,48);
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold
}

.sub-title{
    text-align: left;
    color: rgb(58,139,193);
    font-size: 30px;
    margin-bottom: 20px;
}