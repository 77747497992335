.center-col-home{
    display: flex;
    justify-content: center;
    align-items: center;
}

.bank-img{
    height: 75px;
}

.overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(to right, rgba(0,0,0,.4) , rgba(0,0,0,.5) );
    width: 100%
}

.border-item-home{
    border: 1px #EAEAEA solid;
    justify-content: center;
    max-width: 300px;
    min-height: 250px;
    padding-left: 10px;
    padding-right: 10px;
}

.img-home-style{
    width: 80px;
    height: 80px;
    margin-top: 40px;
    margin-bottom: 10px;
}

.font-size-home{
    font-size: 20px;
}

.custom-button-home{
    border-color: white ;
    border-width: 2px;
    width: 250px;
    padding: 10px;
    font-weight: bold !important;
    font-size: large;
}

.custom-title-carousel-header{
    font-size: 30px;
    margin-bottom: 5px;
}

.custom-title-carousel-content{
    font-size: 20px;
    margin-bottom: 10px;
}

.border-item-home{
    margin-bottom: 50px;
}

.custom-button-home:hover{
    background: rgb(231, 231, 231);
    color: black;    
}

.center-caption{
    height: 100%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.carousel-caption{
    position: absolute;
    bottom: 20px;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: left;
}

.right{
    float: right;
}

.phone-show{
    text-align: left;
}

.phone-screen{
    width: 100%;
    max-width: 300px;
    margin-left: 400px;
    z-index: 999;
    position: relative;
    margin-bottom: 70px;
}

.phone-detail-border{
    position: absolute;
    width: 600px;
    max-width: 98vw;
    border: solid #e7e7e7 1.5px;
    text-align: left;
    height: 450px;
    margin-top: 150px;
    padding: 10px 50px;
}

.phone-step{
    position: absolute;
    margin-top: 50px;
}

.phone-detail h4{
    font-size: 30px;
    color: #606060;
    font-weight: 400;
    white-space: pre;
}

.phone-detail h4::before{
    content: '____ \A';
    color: rgb(58,139,193);
    display: block;
    height: 50px;
}

.phone-detail ul{
    font-size: 18px;
    color: #606060;
    font-weight: 350;
    width: 350px;
    max-width: calc(97vw - 50px);
    padding-left: 22px;
    padding-right: 17px;
    text-align: justify;
}

.card-type{
    height: 70px;
}


@media screen and (max-width: 767px) {
    .phone-screen{
        margin-left: auto;
        margin-right: auto;
        margin-top: -100px;
    }

    .phone-detail-border{
        position: relative;
        margin-top: 50px;
        padding: 10px 30px;
    }

    .phone-step{
        margin-top: auto;
        position: relative;
    }

    .phone-detail ul{
        padding-right: 5px;
        font-size: 17px;
    }

    .home-slide{
        min-height: 400px;
        object-fit: cover;
    }
}


@media screen and (min-width: 992px) {
    .border-item-home{
        margin-bottom: 50px;
    }

    .custom-title-carousel-header{
        margin-bottom: 10px;
        font-size: 40px;
    }

    .custom-title-carousel-content{
        font-size: 20px;
        margin-bottom: 50px;
    }

    .card-type{
        height: 120px;
    }
}

@media screen and (min-width: 768px) {
    .border-item-home{
        margin-bottom: 50px;
    }

    .custom-title-carousel-header{
        margin-bottom: 10px;
        font-size: 40px;
    }

    .custom-title-carousel-content{
        font-size: 20px;
        margin-bottom: 50px;
    }

    .card-type{
        height: 100px;
    }

    .bank-img{
        height: 90px;
    }

    .overlay{
        background-image: linear-gradient(to right, rgba(0,0,0,0) , rgba(0,0,0,.3) 60%);
    }
}