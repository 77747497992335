.banner-content {
    border: 3px solid;
    padding: 5px 10px 5px;
    border-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANQAAACrCAMAAAFec3KoAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAASUExURQAAAP////////////7+/v///3DSMEoAAAAGdFJOUwBggP+wwNv0ngIAAAAJcEhZcwAAFxEAABcRAcom8z8AAAEbSURBVHhe7dw5jsJAAERRD2buf+VJOiAxEl1Dy9V6LyEAq/SFxSKW4/e8dJzHtR2uW+1O7cuvuzRuAB+ZO3Ec9er+R93fY1x+xr38quCoGeNYAIA9LHx1YypkKmQqZCpkKrRwCvh/HphCpkKmQqZCpkJ7Tj3GR07fN/dR2IznaFvA+RcyFTIVMhUyFTIVMhUyFTIVMhUyFVo4BQDT5n4/MsUTfshUyFTIVMhUyFTIVMhUyFTIVMhUaOHXcH5Wdq3y/t+NSolqIaqFqBaiWohqIaqFqBaiWohqIaqFqBaiWohqIaqFqBaiWmwaBQAAsIA3iR1EtRDVQlQLUS1EtRDVQlQLUS1EtRDVQlQLUS1EtRDVQlQLUS12jDrOPy4DCDlmhAw1AAAAAElFTkSuQmCC') 1;
    border-image-repeat: stretch;
    width: 98%;
    font-size: 2rem;
    line-height: 3rem; 
    min-width: 50%;
    display: table;
    margin: 0 auto;
}


.banner {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    color: white;
    margin: 0 !important;
    display: flex;
    min-height: 300px;
    object-fit: cover;
    
}

@media screen and (min-width: 768px) {
    .banner-content {
        padding: 10px 50px 15px;
        font-size: 3rem;
        line-height: 4rem;
    }

    .banner {
        height: 500px;
    }

    .banner-content {
        width: fit-content;
    }
}