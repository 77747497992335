.m-t{
    margin-top: unset;
}

.m-t-step-2{
    margin-top: 470px;
}

.img-style-step-3{
    max-height: 500px;
    max-width: 100%;
}

.m-t-step-3{
    margin-top: unset;
}

.m-t-step-2{
    margin-top: unset;
}

.text-align-step-2-pay-installment{
    text-align: unset; 
}

.p-t-header-pay-qr{
    padding-top: 2rem;
}

@media screen and (min-width: 768px) {
    .m-t{
        margin-top: 340px;
    }

    .m-t-step-2{
        margin-top: 470px;
    }

    .img-style-step-3{
        max-height: 500px;
        max-width: 100%;
    }

    .m-t-step-3{
        margin-top: 250px;
    }
    
    .m-t-step-2{
        margin-top: unset;
    }

    .text-align-step-2-pay-installment{
        text-align: right; 
    }

    .p-t-header-pay-qr{
        padding-top: 2rem;
    }
}

@media screen and (min-width: 992px) {
    .m-t{
        margin-top: 340px;
    }

    .m-t-step-2{
        margin-top: 470px;
    }

    .img-style-step-3{
        max-height: 500px;
        max-width: 100%;
        object-fit: cover;
    }
    
    .m-t-step-3{
        margin-top: 250px;
    }

    .m-t-step-2{
        margin-top: 470px;
    }

    .text-align-step-2-pay-installment{
        text-align: right; 
    }

    .p-t-header-pay-qr{
        padding-top: 10rem;
    }
}
