.mpos-product{
    margin-top: 80px;
}

.text-align-step-1{
    text-align: center; 
    margin-bottom: 50px;
}

.text-align-step-2{
    text-align: center; 
    margin-bottom: 50px;
}

.text-align-step-3{
    text-align: center; 
    margin-bottom: 50px;
}

.style-image-step-2{
    background-repeat: no-repeat;
    padding-top: 30px;
    background-size: cover;
    background-position: center center;
}

.style-image-step-3{
    background-repeat: no-repeat;
    padding-bottom: 100px;
    padding-top: 10px;
    background-size: cover;
    background-position: 100% 100%;
}

.p-l-10rem{
  padding-left: unset;  
}

.p-r-10rem{
    padding-right: unset;
}

.p-t-header-pay-card{
    padding-top: 3rem;
}

.p-b-10rem{
    padding-bottom: 10rem;
}

.icon-ul{
    list-style-image: url('../images/icon_li.png')
}

.p-t-step-3{
    padding-top: 50px;
}

.text-align-left{
    text-align: left;
    font-size: 20px;
    padding: 10px;
}

.img-style-step-3-pay-card{
    max-width: 95%;
    height: 300px;
    object-fit: contain;
    margin-left: 10px;
    margin-right: 10px;
}


.banner-pay-card {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    color: red;
    margin: 0 !important;
    display: flex;
    min-height: 300px;
    object-fit: cover;    
}

.h1-pay-card{
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: rgb(58,139,193);
    font-weight: 400;
}

.m-l-step-1{
    margin-left: unset;
}

.m-t-step-2-pay-card{
    margin-top: unset;
}

.text-align-step-2-image-order{
    text-align: unset; 
}

@media screen and (min-width: 768px) {

    .text-align-step-1{
        text-align: center; 
        margin-bottom: unset;
    }

    .text-align-step-2{
        text-align: center; 
        margin-bottom: unset;
    }

    .text-align-step-3{
        text-align: center; 
        margin-bottom: unset;
    }

    .style-image-step-2{
        background-repeat: no-repeat;
        padding-top: 130px;
        background-size: cover;
        background-position: center center;
        padding-bottom: 130px;
    }

    .style-image-step-3{
        background-repeat: no-repeat;
        padding-bottom: 100px;
        padding-top: 200px;
        background-size: cover;
        background-position: 100% 100%;
    }

    .float-left-pay-card{
        text-align: unset;
        margin-bottom: 20px;
    }

    .p-l-10rem{
        padding-left: 8rem;  
      }

      
    .img-style-step-3-pay-card{
        max-width: 100%;
        height: 250px;
        max-width: unset;
    }

    .p-r-10rem{
        padding-right: 10rem;
    }

    .h1-pay-card{
        margin-top: 7rem;
        margin-bottom: 2rem;
        color: rgb(58,139,193);
        font-weight: 400;
    }

    .p-t-header-pay-card{
        padding-top: 2rem;
    }

    .p-t-step-3{
        padding: 220px;
    }

    .m-t-step-2-pay-card{
        margin-top: unset;
    }

    
    .text-align-step-2-image-order{
        text-align: right; 
    }
}

@media screen and (min-width: 992px) {

    .text-align-step-1{
        text-align: left; 
        margin-bottom: unset;
    }

    .text-align-step-2{
        text-align: left; 
        margin-bottom: 50px;
    }

    .text-align-step-3{
        text-align: left; 
        margin-bottom: unset;
    }

    .style-image-step-2{
        background-repeat: no-repeat;
        padding-top: 230px;
        background-size: cover;
        background-position: center center;
        padding-bottom: 230px;
    }

    .style-image-step-3{
        background-repeat: no-repeat;
        padding-bottom: 100px;
        padding-top: 200px;
        background-size: cover;
        background-position: 100% 100%;
    }

    .p-l-10rem{
        padding-left: 8rem;  
      }

      .img-style-step-3-pay-card{
        max-width: 100%;
        height: 250px;
    }

    .p-r-10rem{
        padding-right: 10rem;
    }

    .h1-pay-card{
        margin-top: 7rem;
        margin-bottom: 2rem;
        color: rgb(58,139,193);
        font-weight: 400;
    }

    .p-t-header-pay-card{
        padding-top: 10rem;
    }

    .p-t-step-3{
        padding: 220px;
    }

    .m-l-step-1{
        margin-left: unset;
    }

    .m-t-step-2-pay-card{
        margin-top: 470px;
    }

    .text-align-step-2-image-order{
        text-align: right; 
    }

    .mpos-product{
        margin-top: 150px;
    }
}


