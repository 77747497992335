.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 155px;
  color: #606060;
  font-family: "Helvetica Neue", Helvetica, Arial, "Roboto", sans-serif, sans-serif;
  overflow-x: hidden;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  background-color: #f5f5f5;
}

h1{
  margin-top: 7rem;
  margin-bottom: 2rem;
  color: rgb(58,139,193);
  font-weight: 400;
}

.banner-content-product {
  border: 3px  solid;
  padding: 5px 10px 5px;
  border-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANQAAACrCAYAAAAeqrIMAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAXEQAAFxEByibzPwAAAd5JREFUeF7t3UsJA1EQRcGRGikjKbSCOJsfiYJ3NoEquNAGzrq3mflcO8xsbe/LHdSxAcuelgQFDUFBSFAQEhSEBAUhQUFIUBASFIQEBSFBQUhQEBIUhAQFIUFBSFAQEhSEBAUhQUFIUBASFIQEBSFBQUhQEBIUhAQFIUFBSFAQEhSEBAUhQUFIUBASFIQEBSFBQUhQEBIUhAQFIUFBSFAQEhSEBAUhQUFIUBASFIQEBSFBQUhQEBIUhAQFIUFBSFAQEhSEBAUhQUFIUBASFIQEBSFBQUhQEBIUhAQFIUFBSFAQEhSEBAUhQUFIUBASFIS0BAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwJ+bmf17Aguelvy0gcbTkqCgISgICQpCgoKQoCAkKAgJCkKCgpCgICQoCAkKQoKCkKAgJCgICQpCgoKQoCAkKAgJCkKCgpCgICQoCAkKQoKCkKAgJCgICQpCgoKQoCAkKAgJCkKCgpCgICQoCAkKQoKCkKAgJCgICQpCgoKQoCAkKAgJCkKCgpCgICQoCAkKQoKCkKAgJCgICQpCgoKQoCAkKAgJCkKCgpCgICQoCAkKQoKCkKAgJCgICQpCgoKQoCAkKAgJCkKCgtAvqP0+zGx18zoB7kVxX752fdsAAAAASUVORK5CYII=') 1;
  border-image-repeat: stretch;
  width: fit-content;
  font-size: 2rem;
  min-width: 50%;
  margin: 0 auto;
}

.navbar-light .navbar-nav .nav-link.active{
  color: rgb(58,139,193);
}

a.link, a.link:link, a.link:visited{
  color: rgb(81,147,184);
  text-decoration: none;
  font-size: large;
}

a.link:hover{
  color: rgb(58,139,193);
  font-weight: 600;
}

div {
  transition: all 0.2s;
}