.footer-top{
    padding: 1rem;
    background: rgb(55,141,190);
    color: white;
    text-align: center;
    margin: 0 !important;
}

.footer-title{
    color: rgb(2,80,145);
    font-size: 1.5rem;
    text-transform: uppercase;
    margin: 1rem 0;
}

.footer-col{
    border-right:#93bbd3 solid 1px;
}

.footer-col-1{
    border-right: none;
}

.footer-col-2{
   border-right: none;
}

.footer-col-3{
   border-right: none;
}

.footer-col-4{
   border-right: none;
}

.footer-bottom{
    background: rgb(212, 216, 215);
    text-align: left;
    margin: 0 !important;
}

.footer-custom{
    text-align: center;
}

.center-icon-left{
    text-align: center;
    margin-bottom: 7px;
}

.center-icon-right{
    text-align: center;
}

.margin-icon-bottom{
    text-align: left;
    margin-left: unset;
}

.text-hotline{
    font-size: 25px
}

.text-email{
 font-size: 20px   
}


@media screen and (min-width: 768px) {
    .footer-col-1{
        border-right: 1px white solid;
   }
   
   .footer-col-2{
       border-right: none;
   }
   
   .footer-col-3{
    border-right: 1px white solid;
   }
   
   .footer-col-4{
       border-right: none;
   }

    .footer-custom{
        text-align: unset;
   }

   .footer-top{
        padding: 5rem;
        background: rgb(55,141,190);
        color: white;
        text-align: left;
    }

    .center-icon-left{
        text-align: center;
    }
    
    .center-icon-right{
        text-align: center;
    }

    .margin-icon-bottom{
        text-align: left;
        margin-left: unset;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
    .text-hotline{
        font-size: 20px
    }
    
    .text-email{
     font-size: 15px
    }
    
    .app-qr{
        margin-left: 30px;
    }

}

@media screen and (min-width: 1200px) {
    .footer-col-1{
        border-right: 1px white solid;
   }
   
   .footer-col-2{
        border-right: 1px white solid;
   }
   
   .footer-col-3{
       border-right: 1px white solid;
   }
   
   .footer-col-4{
        border-right: none;
  }

    .footer-custom{
        text-align: unset;
   }

   .footer-top{
        padding: 5rem;
        background: rgb(55,141,190);
        color: white;
        text-align: left;
    }

    .center-icon-left{
        text-align: left;
    }
    
    .center-icon-right{
        text-align: right;
    }

    .margin-icon-bottom{
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
    }
}
